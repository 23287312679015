// import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import {
//   sectionDataAction,
//   userSectionDataAction,
// } from "../../network/store/action/SectionResponseAction";
// import { Table } from "react-bootstrap";
// import GcSection from "../../components/gc_section";
// import GcOption from "../../components/gc_option";
// import GcUserLock from "../../components/gc_user_locks";

// function UserGc() {
//   const dispatch = useDispatch();

//   const [resultList, setResultList] = useState([]);

//   useEffect(() => {
//     getList();
//   }, []);

//   const getList = async () => {
//     await dispatch(userSectionDataAction({ userId: 2 }, dispatch)).then(
//       (reponse) => {
//         if (reponse.result) {
//           setResultList(reponse.result);
//         }
//       }
//     );
//   };

//   return (
//     <>
//       <div className="">
        
//           <h5 className="account-statement-container">Gc</h5>
        
//         <div className="row m-3">
//           <div className="col-md-12 card-1 h-scroll">
//             {resultList && resultList.length > 0 ? (
//               resultList.map((item, index) => {
//                 return (
//                   <div>
//                     {/* <GcSection item ={item}/> */}
//                     <GcUserLock
//                       item={item}
//                       onUpdate={() => {
//                         getList();
//                       }}
//                     />
//                   </div>
//                 );
//               })
//             ) : (
//               <div></div>
//             )}

//             {/* {
//                 resultList.map((item,index)=>{

//                   return  <div>
//                     <label>{item.name}</label>

                    
//                   </div>
//                 })
//             } */}

//             {/* <table className="table table-hover align-middle">
//               <thead className="thead-dark">
//                 <tr>
//                   <th scope="col">Id</th>
//                   <th scope="col">Name</th>
    
//                 </tr>
//               </thead>
//               {resultList && resultList.length > 0 ? (
//                 <tbody>
//                   {resultList.map((el, i) => {
//                     return (
                    

//                       <tr key={i}>
                      
//                         <td className="align-middle">{el.id}</td>
//                         <td className="align-middle">{el.name}</td>
                     
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               ) : (
//                 <p>No Data found</p>
//               )}
//             </table> */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
// export default UserGc;



import React from 'react'

function UserGc() {
  return (
    <>
    <div className='account-statement-container'>
    <h5 className="account-statement-title"> Lock Application</h5>
    <div>
     <fieldset>
        <legend>Event Type:</legend>
        Cricket <input type='checkbox'/>
        Scoccer <input type='checkbox'/>
        Tennis <input type='checkbox'/>
        Horse <input type='checkbox'/>
        GreyHound <input type='checkbox'/>
     </fieldset>
    </div>
    <div>
     <fieldset>
        <legend>Type:</legend>
        All Odds <input type='checkbox'/>
        Exch Bookmaker <input type='checkbox'/>
        All Bookmaker <input type='checkbox'/>
        BetFair Fancy <input type='checkbox'/>
        Exch Fansy <input type='checkbox'/>
        Other Fansy <input type='checkbox'/>
     </fieldset>
    </div>
    <div>
     <fieldset>
        <legend>Market Type:</legend>
        Cricket <br/> 
        Match Odds<input type='checkbox'/>
        Bookmaker <input type='checkbox'/>
        Special Maker <input type='checkbox'/>
        Tournament Winner <input type='checkbox'/>
        Tied Match <input type='checkbox'/>
        Completed Match <input type='checkbox'/>
        To Win The Toss <input type='checkbox'/>
        1st Inning Runs <input type='checkbox'/>
        1st Inning 6 Ovr Line <input type='checkbox'/>
        1st Inning 10 Ovr Line <input type='checkbox'/>
        1st Inning 15 Ovr Line <input type='checkbox'/>
        1st Inning 20 Ovr Line <input type='checkbox'/>
        2nd Inning Runs <input type='checkbox'/>
        2nd Inning 6 Ovr Line <input type='checkbox'/>
        2nd Inning 10 Ovr Line <input type='checkbox'/>
        2nd Inning 15 Ovr Line <input type='checkbox'/>
        Over<input type='checkbox'/>
        Batsmen<input type='checkbox'/>
        Single Batsmen<input type='checkbox'/>
        Odd Even<input type='checkbox'/>
        Ball By Ball<input type='checkbox'/>
        Three Selection<input type='checkbox'/>
        Lottery<input type='checkbox'/>
        <br/>
        Soccer<br/>
        Match Odds<input type='checkbox'/>
        Bookmaker<input type='checkbox'/>
        Special Maker<input type='checkbox'/>
        Over Under 05<input type='checkbox'/>
        Over Under 15<input type='checkbox'/>
        Over Under 25<input type='checkbox'/>
        Over Under 35<input type='checkbox'/>
        Lottery<input type='checkbox'/>
        <br/>
        Tennis<br/>
        Match Odds<input type='checkbox'/>
        Bookmaker<input type='checkbox'/>
        Special Maker<input type='checkbox'/>
        Lottery<input type='checkbox'/>
        <br/>
        Horse<br/>
        Win<input type='checkbox'/>
        <br/>
        GreyHound<br/>
        Win<input type='checkbox'/>



     </fieldset>
    </div>

    </div>
    </>
  )

}

export default UserGc