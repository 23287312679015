import React from "react";

import "./ClientsForm.css";

function ClientForm() {
  return (
    <div className="wrapper"> 
      <div className="container-fluid container-padding-0">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="mainbox" style={{ display: "block" }}>
              <h5 className="text-blue">Add/Edit Client Account</h5>
              <span></span>
              <div className="row supermaster mt-3">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <fieldset>
                    <legend>Account Details:</legend>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Account Type:</label>
                          <select className="f-control" name="account_type">
                            <option>Select User Type</option>
                            <option value="Admin">Admin</option>
                            <option value="SuperMaster">Super Master</option>
                            <option value="SubSuperMaster">
                              Sub Super Master
                            </option>
                            <option value="Master">Master</option>
                            <option value="Client">Client</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Client Name:</label>
                          <input
                            className="f-control"
                            placeholder="Client Name"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div
                  className="col-12 col-sm-12 col-lg-6 col-md-12"
                  style={{ marginTop: "20px" }}
                >
                  <fieldset>
                    <legend>Personal Details:</legend>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6">
                            <div className="form-group">
                              <label>User Name:</label>
                              <input type="text" className="f-control" />
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6">
                            <div className="form-group">
                              <label>Password:</label>
                              <input type="password" className="f-control" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6">
                            <div className="form-group">
                              <label>Retype Password:</label>
                              <input type="text" className="f-control" />
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6">
                            <div className="form-group">
                              <label>Reference Name:</label>
                              <input
                                type="text"
                                className="f-control"
                                placeholder="Reference Name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="row supermaster mt-2">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <fieldset>
                    <legend>Commission:</legend>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="game-title-small">
                          Cricket / Soccer / Tennis
                        </div>
                        <div className="text-center mb-3">
                          <span className="in-title">Commission:</span>
                          <span className="text-black">M.O</span>
                          <span className="d-inline inp-25">
                            <input
                              style={{ width: "60px" }}
                              className="f-control wid-15 d-inline"
                              value="1"
                            />
                            %
                          </span>
                          <span className="in-title">(1%)</span>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4"></div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4"></div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="row supermaster mt-2">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <fieldset>
                    <legend>Sport & Casino Balance</legend>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Credit Reference:</label>
                          <input
                            type="number"
                            className="f-control"
                            placeholder="Credit Reference"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Add Deposit:</label>
                          <input
                            type="number"
                            className="f-control"
                            placeholder="Add Deposit"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>Deposit Remark:</label>
                          <input
                            type="text"
                            className="f-control"
                            placeholder="Deposit Remark"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                  style={{ marginTop: "30px" }}
                >
                  <fieldset>
                    <legend>RC Balance</legend>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>RC Credit Reference:</label>
                          <input
                            type="number"
                            className="f-control"
                            placeholder="Credit Reference"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>RC Add Deposit:</label>
                          <input
                            type="number"
                            className="f-control"
                            placeholder="Add Deposit"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label>RC Deposit Remark:</label>
                          <input
                            type="text"
                            className="f-control"
                            placeholder="Deposit Remark"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-3 col-lg-3"
                  style={{ marginTop: "60px" }}
                >
                  <div className="form-group">
                    <label>Master Password:</label>
                    <input type="text" className="f-control" />
                  </div>
                </div>
                <div className="supermaster add-client-mt-4">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="text-right">
                      <button className="btn btn-blu accbtn mt-0">
                        Create Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientForm;
