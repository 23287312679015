import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import './BetHistory.css';
const dateFormat = "DD/MM/YYYY";

function BetHistory() {
  const sportOptions = ["Sports", "Casino", "Binary", "SportBook"];
  const sports = ["Cricket", "Soccer", "Tennis"];
  const eventOptions = ["Select Event"];
  const marketTypes = [""];
  const marketTypeOptions = [""];
  const statusOptions = ["WON", "LOST", "VOID", "DECLARED"];

  const [selectedSport, setSelectedSport] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedMarketType, setSelectedMarketType] = useState("");
  const [selectedMarketTypeOption, setSelectedMarketTypeOption] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [startDate, setStartDate] = useState(dayjs("01/09/2023", dateFormat));
  const [endDate, setEndDate] = useState(dayjs("01/09/2023", dateFormat));
  const [searchText, setSearchText] = useState("");

  const handleSportChange = (value) => {
    setSelectedSport(value);
  };

  const handleEventChange = (value) => {
    setSelectedEvent(value);
  };

  const handleMarketTypeChange = (value) => {
    setSelectedMarketType(value);
  };

  const handleMarketTypeOptionChange = (value) => {
    setSelectedMarketTypeOption(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    //  search functionality here using the selected options, dates, and search text
  };

  const handleReset = () => {
    setSelectedSport("");
    setSelectedEvent("");
    setSelectedMarketType("");
    setSelectedMarketTypeOption("");
    setSelectedStatus("");
    setSearchText("");
    setStartDate(dayjs("01/09/2023", dateFormat));
    setEndDate(dayjs("01/09/2023", dateFormat));
  };

  return (
    <div className="bet-history-container">
      <h5 className="bet-history-title">Bet History</h5>
      <DatePicker
        value={startDate}
        format={dateFormat}
        onChange={handleStartDateChange}
      />
      <DatePicker
        value={endDate}
        format={dateFormat}
        onChange={handleEndDateChange}
      />
      <select
        title="Event Type"
        className="bet-option-select"
        value={selectedSport}
        onChange={(e) => handleSportChange(e.target.value)}
      >
        <option>Select Sport</option>
        {sportOptions.map((sport, index) => (
          <option key={index} value={sport}>
            {sport}
          </option>
        ))}
      </select>
      <select
        title="Sport"
        className="bet-option-select"
        value={selectedEvent}
        onChange={(e) => handleEventChange(e.target.value)}
      >
        <option>Select Event</option>
        {sports.map((event, index) => (
          <option key={index} value={event}>
            {event}
          </option>
        ))}
      </select>
      <select
        title="Event"
        className="bet-option-select"
        value={selectedMarketType}
        onChange={(e) => handleMarketTypeChange(e.target.value)}
      >
        <option value="" disabled >Select Market Type</option>
        {marketTypes.map((marketType, index) => (
          <option key={index} value={marketType}>
            {marketType}
          </option>
        ))}
      </select>
      <select
        title="Market Type"
        className="bet-option-select"
        value={selectedMarketTypeOption}
        onChange={(e) => handleMarketTypeOptionChange(e.target.value)}
      >
        <option value="" disabled >Market Type Option</option>
        {marketTypeOptions.map((marketTypeOption, index) => (
          <option key={index} value={marketTypeOption}>
            {marketTypeOption}
          </option>
        ))}
      </select>
      <input
        type="text"
        className="bet-search-input"
        placeholder="Search by Client"
        value={searchText}
        onChange={handleSearchChange}
      />
      <select
        title="Bet Status"
        className="bet-option-select"
        value={selectedStatus}
        onChange={(e) => handleStatusChange(e.target.value)}
      >
        <option>Select Status</option>
        {statusOptions.map((status, index) => (
          <option key={index} value={status}>
            {status}
          </option>
        ))}
      </select>

      <div className="buttons">
        <button className="go-button" onClick={handleSearch}>
          Go
        </button>
        <button className="reset-button" onClick={handleReset}>
          Reset
        </button>
      </div>
    </div>
  );
}

export default BetHistory;
