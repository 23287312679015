import React, { useState } from "react";
import "./Dashboard.css";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function Dashboard() {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (dates) => {
    setSelectedDate(dates);
  };

  const handleSubmit = () => {
    setSelectedDate(selectedDate);
  };

  const handleReset = () => {
    setSelectedDate(null);
  };

  return (
    <div className="wrapper">
      <div className="container-fluid container-padding-0 dashboardmain">
        <div>
          <div>
            <h5 className="dashboard-title">Dashboard</h5>
          </div>
        </div>

        <div>
          <div className="dashboard-container">
            <div className="cards">
              <div className="card-body">
                <RangePicker
                  format={"DD-MM-YY"}
                  value={selectedDate}
                  onChange={handleDateChange}
                />
                <div>
                  <button className="sub" onClick={handleSubmit}>
                    SUBMIT
                  </button>
                  <button className="res" onClick={handleReset}>
                    RESET
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>



        {/* CARDS */}



        <div className="row mt-3 text-black ">
          <div className="col-6 col-md-4 col-lg-4 col-sm-6 col-xl-2 mb-3 paddingr-5">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">P&L</h4>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div>
                    <h2 className="mb-0 number-font">
                      <span className="undefined text-dark">0</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 col-sm-6 col-xl-2 mb-3 paddingl-5">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">COMMISION</h4>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div>
                    <h2 className="mb-0 number-font">
                      <span className="undefined text-dark">0</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 col-sm-6 col-xl-2 mb-3 paddingr-5">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">DEPOSIT</h4>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div>
                    <h2 className="mb-0 number-font">
                      <span className="undefined text-dark">0</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 col-sm-6 col-xl-2 mb-3 paddingl-5">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">WITHDRAWL</h4>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div>
                    <h2 className="mb-0 number-font">
                      <span className="undefined text-dark">0</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 col-sm-6 col-xl-2 mb-3 paddingr-5">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">TOTALBETS</h4>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div>
                    <h2 className="mb-0 number-font">
                      <span className="undefined text-dark">0</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 col-sm-6 col-xl-2 mb-3 paddingl-5">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">SPORTSBOOK P&L</h4>
              </div>
              <div className="card-body">
                <div className="d-flex">
                  <div>
                    <h2 className="mb-0 number-font">
                      <span className="undefined text-dark">0</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TOP 5 WINNING */}

        <div className="row text-black dashboard-table">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-3">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">Top 5 Winning Player</h4>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table font-12 table-bordered table-sm addpadding table-striped mb-0">
                    <thead className="thead">
                      <tr>
                        <th className="w-75">Player</th>
                        <th className="w-25 text-right">Amount</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* TOP 5 LOSING */}

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-3">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">Top 5 Losing Player</h4>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table font-12 table-bordered table-sm addpadding table-striped mb-0">
                    <thead className="thead">
                      <tr>
                        <th className="w-75">Player</th>
                        <th className="w-25 text-right">Amount</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* TOP 5 WINNING MARKET */}

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-3">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">Top 5 Winning Market</h4>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table font-12 table-bordered table-sm addpadding table-striped mb-0">
                    <thead className="thead">
                      <tr>
                        <th className="binary-w-30">Player</th>
                        <th className="binary-w-30">Market</th>
                        <th className="binary-w-30 text-right">Amount</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* TOP 5 LOSING MARKET */}

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-3">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold">Top 5 Losing Market</h4>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table font-12 table-bordered table-sm addpadding table-striped mb-0">
                    <thead className="thead">
                      <tr>
                        <th className="binary-w-30">Player</th>
                        <th className="binary-w-30">Market</th>
                        <th className="binary-w-30 text-right">Amount</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* USER COUNT */}

        <div className="row text-black last-row-margin-bottom dashboard-table">
          <div className="col-md-6 col-sm-6 col-xl-3 col-lg-3 mb-3">
            <div className="card overflow-hidden">
              <div className="card-header header-h4-title">
                <h4 className="card-title fw-semibold d-inline-flex">
                  User Count
                </h4>
                <span className="dashboard-user-overall-count-btn">
                  Overall Count
                </span>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table font-12 table-bordered table-sm addpadding table-striped mb-0 mt-0">
                    <thead className="thead">
                      <tr>
                        <th className="w-75">Player</th>
                        <th className="w-25 text-right">Amount</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* SPORTS GAMEPLAY DETAILS */}

          <div className="col-md-6 col-sm-6 col-xl-3 col-lg-3 mb-3">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold ">
                  {" "}
                  Sports Gameplay Details
                </h4>
              </div>
              <div className="card-body p-0">
                <div className="group-form w-50 mb-1 ml-1 mt-1 box">
                  <select className="dashboard-control">
                    <option>Select Sports</option>
                    <option>Cricket</option>
                    <option>Hockey</option>
                    <option>Football</option>
                  </select>
                </div>
                <div className="table-responsive">
                  <table className="table font-12 table-bordered table-sm addpadding table-striped mb-0 mt-0">
                    <tbody>
                      <tr>
                        <td>Total Bets</td>
                        <td className="text-right">0</td>
                      </tr>
                      <tr>
                        <td>Total Bet Amount</td>
                        <td className="text-right">0</td>
                      </tr>
                      <tr>
                        <td>Total P&L</td>
                        <td className="text-right">0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* OTHERS GAMEPLAY DETAILS */}
          <div className="col-md-6 col-sm-6 col-xl-3 col-lg-3 mb-3">
            <div className="card overflow-hidden">
              <div className="card-header">
                <h4 className="card-title fw-semibold ">
                  {" "}
                  Others Gameplay Details
                </h4>
              </div>
              <div className="card-body p-0">
                <div className="group-form w-50 mb-1 ml-1 mt-1 box">
                  <select className="dashboard-control">
                    <option>Select Other</option>
                    <option>Election</option>
                    <option>Binary</option>
                  </select>
                </div>
                <div className="table-responsive">
                  <table className="table font-12 table-bordered table-sm addpadding table-striped mb-0 mt-0">
                    <tbody>
                      <tr>
                        <td>Total Bets</td>
                        <td className="text-right">0</td>
                      </tr>
                      <tr>
                        <td>Total Bet Amount</td>
                        <td className="text-right">0</td>
                      </tr>
                      <tr>
                        <td>Total P&L</td>
                        <td className="text-right">0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

{
  /* <div className="main-cards">
        <div className="card">
          <div className="card-inner">
            <h6>P&L</h6>
          </div>
          <div className="content">
            <h6>0</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <h6>COMMISION</h6>
          </div>
          <div className="content">
            <h6>0</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <h6>DEPOSIT</h6>
          </div>
          <div className="content">
            <h6>0</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <h6>WITHDRAWL</h6>
          </div>
          <div className="content">
            <h6>0</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <h6>TOTALBETS</h6>
          </div>
          <div>
            <h6>0</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-inner">
            <h6>SPORTSBOOK P&L</h6>
          </div>
          <div>
            <h6>0</h6>
          </div>
        </div>
      </div> */
}

{
  /* <div>
        <table className="casino-table">
          <thead>
            <tr>
              <th colSpan="2" style={{color:"#fff"}}>CASINO GAMEPLAY DETAILS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Indian Poker/Live Casino P&L</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Indian Poker II P&L</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Evolution P&L</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Vivo P&L</td>
              <td>0</td>
            </tr>
            <tr>
              <td>WAC P&L</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Betgames P&L</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Casino III P&L</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Total P&L</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div> */
}

{
  /* <div className="tables">
        <div>
          <table className="table">
            <thead>
              <tr>
                <th colSpan="2" style={{ backgroundColor: "#16a3bb " }}>
                  TOP 5 WINNING PLAYERS
                </th>
              </tr>
              <tr>
                <th>Player</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>

        <div>
          <table className="table">
            <thead>
              <tr>
                <th colSpan="2" style={{ backgroundColor: "#16a3bb " }}>
                  TOP 5 LOSING PLAYERS
                </th>
              </tr>
              <tr>
                <th>Player</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>

        <div>
          <table className="table">
            <thead>
              <tr>
                <th colSpan="3" style={{ backgroundColor: "#16a3bb " }}>
                  TOP 5 WINNING MARKETS
                </th>
              </tr>
              <tr>
                <th>Sport</th>
                <th>Market</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>

        <div>
          <table className="table">
            <thead>
              <tr>
                <th colSpan="3" style={{ backgroundColor: "#16a3bb " }}>
                  TOP 5 LOSING MARKETS
                </th>
              </tr>
              <tr>
                <th>Sport</th>
                <th>Market</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
      <div>
        <table className="user-table">
          <thead>
            <tr>
              <th colSpan="2" style={{ backgroundColor: "#16a3bb " }}>
                USER COUNT
                 <span>
                <a href="#" style={{ marginLeft: "760px" }}>
                  Overall Count
                </a>
              </span> 
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Role</td>
              <td>Count</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table className="sportsplay-table">
          <thead>
            <tr>
              <th colSpan={2} style={{ backgroundColor: "#16a3bb" }}>
                GAME SPORTSPLAY DETAILS
              </th>
            </tr>
            <tr>
              <th colSpan={2}>
                <select className="dashboard-control">
                  <option>Select Sports</option>
                  <option>Cricket</option>
                  <option>Hockey</option>
                  <option>Football</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Bets</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Total Bet Amount</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Total P&L</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table className="other-details-table">
          <thead>
            <tr>
              <th colSpan={2} style={{ backgroundColor: "#16a3bb" }}>
                OTHER GAMEPLAY DETAILS
              </th>
            </tr>
            <tr>
              <th colSpan={2}>
                <select className="dashboard-control">
                  <option>Select Other</option>
                  <option>Election</option>
                  <option>Binary</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Bets</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Total Bet Amount</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Total P&L</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div> */
}
