import React, { useState } from 'react';
import './UserDetail.css';
import { Container, Nav, Navbar } from 'react-bootstrap';

function UserDetail() {
  const [searchNumber, setSearchNumber] = useState(''); 

  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
    setSearchNumber(sanitizedValue);
  }

  const isMobileNumberValid = () => {
    return searchNumber.length === 10;
  }
  
  const handleSubmit = () => {
    if (isMobileNumberValid()) {
      console.log('Search Number:', searchNumber);

    } else {
      alert('Please enter a valid mobile number.');
    }
  }

  return (
    <>
      <div className='user-details'>
        <h5 style={{ color: '#16b0c8' }}>User Details</h5>
        <input
          type='text'
          className='form-controls'
          placeholder='Search by Client'
          value={searchNumber}
          onChange={handleSearchChange}
        />
        {/* <button className='submit-button' onClick={handleSubmit}>
          Submit
        </button> */}
        {/* <div>
        <Navbar className='Navbar'>
        <Container className='navLinks'>
          <Navbar.Brand href="#home" className='navLinks'>Details</Navbar.Brand>
          <Nav className="me-auto">
             <Nav.Link to="/transaction" style={{ marginLeft: '100px' }}>Transaction</Nav.Link>
                <Nav.Link href="#features" style={{ marginLeft: '100px' }}>BrtHistory</Nav.Link>
                <Nav.Link href="#pricing" style={{ marginLeft: '100px' }}>Settelments</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
        </div> */}
      </div>
    </>
  );
}

export default UserDetail;
