import React, { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import './AccountStatement.css';

const dateFormat = 'DD/MM/YYYY';

function AccountStatement() {
  const accoptions = ['ALL', 'BALANCE REPORT', 'GAME REPORT', 'SETTLEMENT REPORT', 'BONUS REPORT'];
  const accoptions1 = ['ALL'];
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOption1, setSelectedOption1] = useState('');
  const [selectDate, setSelectDate] = useState(null);
  const [clientName, setClientName] = useState('');
  const [accountStatements, setAccountStatements] = useState([]); 

  const handleOptionChange = (accoptions) => {
    setSelectedOption(accoptions);
  };

  const handleOptionChange1 = (accoptions1) => {
    setSelectedOption1(accoptions1);
  };

  const handleDateChange = (dates) => {
    setSelectDate(dates);
  };

  const handleClientNameChange = (e) => {
    setClientName(e.target.value);
  };

  const handleGo = () => {
    // Make an API request to fetch account statements based on selected options, client name, and date
    // Update 'accountStatements' state with the fetched data
  
  };

  const handleReset = () => {
    setClientName('');
    setSelectedOption('');
    setSelectedOption1('');
    setSelectDate(null);
    setAccountStatements([]);
  };

  return (
    <div className="account-statement-container">
      <h5 className="account-statement-title">Account Statement Report</h5>
      <select
        title='types'
        className="account-option-select"
        value={selectedOption}
        onChange={(e) => handleOptionChange(e.target.value)}
      >
        {accoptions.map((accoption, index) => (
          <option key={index} value={accoption}>
            {accoption}
          </option>
        ))}
      </select>
      <select
        title='Game Types'
        className="account-option-select"
        value={selectedOption1}
        onChange={(e) => handleOptionChange1(e.target.value)}
      >
        {accoptions1.map((accoption, index) => (
          <option key={index} value={accoption}>
            {accoption}
          </option>
        ))}
      </select>
      <input
        className="account-search-input"
        type="text"
        placeholder="Search by Client"
        value={clientName}
        onChange={handleClientNameChange}
      />
      <DatePicker defaultValue={dayjs("01/09/2023", dateFormat)} format={dateFormat}
      onChange={handleDateChange}
      value={selectDate} />
      <DatePicker
        defaultValue={dayjs("01/09/2023", dateFormat)}
        format={dateFormat}
        onChange={handleDateChange}
        value={selectDate}
      />
      <div className='buttons'>
        <button className='go-button' onClick={handleGo}>Go</button>
        <button className='reset-button' onClick={handleReset}>Reset</button>
        </div>


      {/* Display account statements */}
      <ul>
        {accountStatements.map((statement, index) => (
          <li key={index}>
            {/* Display statement details */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AccountStatement;