import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "./CuttingHistory.css";
const dateFormat = "DD/MM/YYYY";
function CuttingHistory() {
  const options = ["Cricket", "Soccer", "Tennis"];
  const option1 = ["Select Event"];
  const option2 = [""];
  const option3 = [""];
  const [option, setOption] = useState("");
  const [opt, setOpt] = useState("");
  const [opt2, setOpt2] = useState("");
  const [opt3, setOpt3] = useState("");
  const handleOptionChange6 = (options) => {
    setOption(options);
  };
  const handleOptionChange7 = (option1) => {
    setOpt(option1);
  };
  const handleOptionChange8 = (option2) => {
    setOpt2(option2);
  };
  const handleOptionChange9 = (option3) => {
    setOpt3(option3);
  };
  return (
    <div className="cutting-container">
      <h5 className="cutting-title">Cutting History</h5>
      <DatePicker
        defaultValue={dayjs("01/09/2023", dateFormat)}
        format={dateFormat}
      />
      <DatePicker
        defaultValue={dayjs("01/09/2023", dateFormat)}
        format={dateFormat}
      />
      <select
        title="Sport"
        className="cutting-option-select"
        value={option}
        onChange={(e) => handleOptionChange6(e.target.value)}
      >
        <option>Select Sport</option>
        {options.map((options, index) => (
          <option key={index} value={options}>
            {options}
          </option>
        ))}
      </select>

      <select
        title="Event"
        className="cutting-option-select"
        value={opt}
        onChange={(e) => handleOptionChange7(e.target.value)}
      >
        <option>Select Event</option>
        {option1.map((option1, index) => (
          <option key={index} value={option1}>
            {option1}
          </option>
        ))}
      </select>
      <select
        title="Market"
        className="cutting-option-select"
        value={opt2}
        onChange={(e) => handleOptionChange8(e.target.value)}
      >
        <option>Select Market Type</option>
        {option2.map((option2, index) => (
          <option key={index} value={option2}>
            {option2}
          </option>
        ))}
      </select>

      <select
        title="Market Type"
        className="cutting-option-select"
        value={opt3}
        onChange={(e) => handleOptionChange9(e.target.value)}
      >
        <option> Market Type</option>
        {option3.map((option3, index) => (
          <option key={index} value={option3}>
            {option3}
          </option>
        ))}
      </select>
      <div>
        <button className="go-button">Go</button>
        <button className="reset-button">Reset</button>
      </div>
    </div>
  );
}

export default CuttingHistory;
