import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import './SportsRevenue.css';
const dateFormat = "DD/MM/YYYY";


function SportsRevenue() {
  const [startDate, setStartDate] = useState(dayjs("01/01/2023", dateFormat));
  const [endDate, setEndDate] = useState(dayjs("01/01/2023", dateFormat));
  const handleGo = () => {};
  const handleReset = () => {
    setStartDate(dayjs("01/01/2023", dateFormat));
    setEndDate(dayjs("01/01/2023", dateFormat));
  };
  const handleStartdate = (date) => {
    setStartDate(date);
  };
  const handleEnddate = (date) => {
    setEndDate(date);
  };
  return (
    <div className="sports-revenue-container">
      <h5 className="sports-revenue-title">Sports Revenue</h5>
      <DatePicker
        value={startDate}
        format={dateFormat}
        onChange={handleStartdate}
      />
      <DatePicker
        value={endDate}
        format={dateFormat}
        onChange={handleEnddate}
      />
      <div>
        <button className="go-button" onClick={handleGo}>
          Go
        </button>
        <button className="reset-button" onClick={handleReset}>
          Reset
        </button>
      </div>
    </div>
  );
}

export default SportsRevenue;
