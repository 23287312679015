import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction } from "../../network/store/action/SectionResponseAction";
import noImage from "../../assets/no_image.jpg";
import GMCTPagination from "../../components/pagination";
// import { Table } from "react-bootstrap";
import {
  FaUserLock,
  FaTrash,
  FaEdit,
  FaPlus,
  FaAngleDoubleDown,
} from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import { MdOutlinePassword } from "react-icons/md";

import UserRow from "../../components/UserRow";
import SectionsUpdateForm from "./Sections-update-form";

function Sections() {
  // to get api data

  const dispatch = useDispatch();

  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  // list of items
  const [resultList, setResultList] = useState([]);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [selectedForEdit, setSelectedForEdit] = useState({});
  const [showRequestForm, setShowRequestForm] = useState(false);

  useEffect(() => {
    geList(pageLimit, pageOffset);
  }, []);

  useEffect(() => {
    geList(pageLimit, pageOffset);
  }, [pageLimit, pageOffset]);

  const geList = async (limit, offset) => {
    await dispatch(
      listAction(
        {
          limit: limit,
          offset: offset,
          roleId: "2",
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
        setTotalCount(Number(reponse.itemCount.totalFilterCount));
        setPageCount(reponse.itemCount.pageCount);
      } else {
        resetFilter();
      }
    });
  };

  const editItem = (item) => {
    setSelectedForEdit(item);
    setShowRequestForm(true);
  };

  const onCloseEvent = () => {
    setShowRequestForm(false);

    setResultList([]);
    setSelectedForEdit({});

    geList(pageLimit, pageOffset);
    resetFilter();
  };

  const resetFilter = () => {
    setPageOffset(0);
    setTotalCount(0);
    setResultList([]);
    setPageCount(0);
    setActivePage(1);
  };

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  };
  return (
    <>
      {showRequestForm && (
        <div
          style={{
            position: "fixed",
            marginTop: "10px",
            backgroundColor: "rgba(0,0,0,0.5)",
            // backgroundColor: "#FFFFFF",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-8">
              <SectionsUpdateForm
                onClose={() => {
                  console.log("AddCategory : closed.");
                  onCloseEvent();
                }}
                itemInfo={selectedForEdit}
                pageName="Admin"
                roleId="2"
              />
            </div>
          </div>
        </div>
      )}

      <div className="account-statement-container">
        <h4 className="account-statement-title">Sections</h4>

        <div className="row justify-content-around tble">
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-primary"
              onClick={() => setShowRequestForm(true)}
            >
              Add Section
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {resultList && resultList.length > 0 ? (
                  <tbody>
                    {resultList.map((el, i) => {
                      return (
                        // <UserRow el= {el} i ={i}/>

                        <tr key={i}>
                          {/* <th scope="row">{od.id}</th> */}
                          <td className="align-middle">{el.id}</td>
                          <td className="align-middle">{el.name}</td>
                          {/* <td className="align-middle">
                          <p>{el.prices.name}</p>
                        </td> */}
                          <td className="align-middle">
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={el.status === "YES" ? true : false}
                              />
                            </div>
                            {/* <p><input class="form-check-input" type="checkbox" role="switch"  checked={el.status === "YES" ? true : false}/></p> */}
                          </td>
                          <td className="align-middle">
                            <span
                              className="edit-icon-btnn"
                              onClick={() => {
                                editItem(el);
                              }}
                            >
                              <FaEdit />
                            </span>{" "}
                            {/* <span className="delete-icon-btn">
                            <FaTrash />
                          </span> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <p>No Data found</p>
                )}
              </table>
            </div>
            {/* 
            <GMCTPagination
              pageCount={pageCount}
              activePage={activePage}
              totalCount={totalCount}
              onPageChange={(page) => handlePageChange(page)}
              onLimitChange={(e) => handleLimitChange(e)}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default Sections;
