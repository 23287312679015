import { DEBIT_API_PATH } from "../../config/apiPaths";
import {  updateRequest } from "../../service/DebitService";
import { postRequest } from "../../service/config/PostRequest";





export const updateAction = (requestBody) => async (dispatch) => {
  const response = await dispatch(updateRequest(requestBody));
  console.log(response);
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    console.log(response);
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};