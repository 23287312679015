import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import  "./TransactionList.css";

import GMCTPagination from "../../components/pagination";
import { transactionLogAction } from "../../network/store/action/WalletResponseAction";

function TransactionList() {
  // to get api data

  const dispatch = useDispatch();
  // list of items
  const [resultList, setResultList] = useState([]);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(100);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    geList(pageLimit, pageOffset);
  }, []);

  useEffect(() => {
    geList(pageLimit, pageOffset);
  }, [pageLimit, pageOffset]);

  const geList = async (limit, offset) => {
    await dispatch(transactionLogAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
      }
    });
  };

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  };
  return (
    <>
      <div className="container-fluid dashboard">
        <h5 style={{ marginTop: "30px" }} className="account-statement-title">
          Transaction List
        </h5>

        <div className="row">
          <div className=""col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12>
            <div className="table-responsive">
              <table className="table font-12 table-bordered border-sm addpadding mt-2 whitespace"
              id="example">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Chips</th>
                    <th>Customer Name</th>
                    <th>Approved By</th>
                    <th>Type</th>
                  </tr>
                </thead>
                {resultList && resultList.length > 0 ? (
                  <tbody>
                    {resultList.map((el, i) => {
                      return (
                        // <UserRow el= {el} i ={i}/>

                        <tr key={i}>
                          <td>{el.id}</td>
                          <td>{el.points}</td>
                          <td>{el.username}</td>
                          <td>{el.approvedBy}</td>
                          <td
                            style={{
                              color: el.type == "DEBIT" ? "red" : "green",
                            }}
                          >
                            {el.type}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <p>No Data found</p>
                )}
              </table>
            </div>

            {/* <GMCTPagination
              pageCount={pageCount}
              activePage={activePage}
              totalCount={totalCount}
              onPageChange={(page) => handlePageChange(page)}
              onLimitChange={(e) => handleLimitChange(e)}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default TransactionList;
