import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "./CurrentBets.css";
const dateFormat = "DD/MM/YYYY";

function CurrentBets() {
  const options = ["Cricket", "Soccer", "Tennis"];
  const option1 = ["Select Event"];
  const option2 = [""];
  const option3 = [""];
  const [option, setOption] = useState("");
  const [opt, setOpt] = useState("");
  const [opt2, setOpt2] = useState("");
  const [opt3, setOpt3] = useState("");
  const [startDate, setStartDate] = useState(dayjs("01/09/2023", dateFormat));
  const [endDate, setEndDate] = useState(dayjs("01/09/2023", dateFormat));
  const [searchText, setSearchText] = useState("");

  const handleOptionChange1 = (value) => {
    setOption(value);
  };

  const handleOptionChange2 = (value) => {
    setOpt(value);
  };

  const handleOptionChange3 = (value) => {
    setOpt2(value);
  };

  const handleOptionChange4 = (value) => {
    setOpt3(value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    // search fun using 'option', 'opt', 'opt2', 'opt3', 'searchText', 'startDate', and 'endDate'
  };

  const handleReset = () => {
    setOption("");
    setOpt("");
    setOpt2("");
    setOpt3("");
    setSearchText("");
    setStartDate(dayjs("01/09/2023", dateFormat));
    setEndDate(dayjs("01/09/2023", dateFormat));
  };

  return (
    <div className="current-bets-container">
      <h5 className="current-bets-title">Current Bets</h5>

      <DatePicker
        value={startDate}
        format={dateFormat}
        onChange={handleStartDateChange}
      />
      <DatePicker
        value={endDate}
        format={dateFormat}
        onChange={handleEndDateChange}
      />

      <div className="select-container">
        <select
          title="Sport"
          className="current-option-select"
          value={option}
          onChange={(e) => handleOptionChange1(e.target.value)}
        >
          <option>Select Sport</option>
          {options.map((sport, index) => (
            <option key={index} value={sport}>
              {sport}
            </option>
          ))}
        </select>
        <select
          title="Event"
          className="current-option-select"
          value={opt}
          onChange={(e) => handleOptionChange2(e.target.value)}
        >
          <option>Select Event</option>
          {option1.map((event, index) => (
            <option key={index} value={event}>
              {event}
            </option>
          ))}
        </select>
        <select
          title="Market"
          className="current-option-select"
          value={opt2}
          onChange={(e) => handleOptionChange3(e.target.value)}
        >
          <option value="" disabled>
            Select Market Type
          </option>
          {option2.map((marketType, index) => (
            <option key={index} value={marketType}>
              {marketType}
            </option>
          ))}
        </select>

        <select
          title="Market Types"
          className="current-option-select"
          value={opt3}
          onChange={(e) => handleOptionChange4(e.target.value)}
        >
          <option value="" disabled >Market Type</option>
          {option3.map((marketType, index) => (
            <option key={index} value={marketType}>
              {marketType}
            </option>
          ))}
        </select>
      </div>
      <input
        type="text"
        className="current-search-input"
        placeholder="Search by Client"
        value={searchText}
        onChange={handleSearchChange}
      />
      <div>
        <button className="go-button" onClick={handleSearch}>
          Go
        </button>
        <button className="reset-button" onClick={handleReset}>
          Reset
        </button>
      </div>
    </div>
  );
}

export default CurrentBets;
