import React from "react";
import "./Clients.css";
import { HiArrowsUpDown } from "react-icons/hi2";
import { Link } from "react-router-dom";

function Clients() {
  return (
    <div className="wrapper">
      <div className="container-fluid container-padding-0">
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-12 col-md-12">
            <div className="mainbox">
              <div>
                <h5 className="text-blue mb-2">
                  <span>Client List</span>
                  <div className="row user-header">
                    <div className="col-12 col-sm-8 col-lg-8 col-md-8 col-xl-8">
                      <div className="d-flex float-left">
                        <div className="form-group mb-0 d-inline">
                          <input
                            className="f-control d-inline-block"
                            type="text"
                            placeholder="Search"
                            value=""
                          />
                        </div>
                        <div className="form-group mb-0 d-inline pl-1 ">
                          <div className="form-group font-12 autocomplete-wrapper d-inline-block mb-1">
                            <div style={{ display: "inline-block" }}>
                              <input
                                placeholder="Search By Client"
                                autoComplete="off"
                                aria-autocomplete="list"
                                aria-expanded="false"
                                role="combobox"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          className="btn btn-sm d-inline csv-icon p-0 pl-1"
                          title="Export Data"
                        >
                          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNTL48hdFgcI1b0Di92lvwE5dULJ5Y7z4-Nc3pGaZd3_66TTlHsFC011gtEA&s" />
                        </button>
                        <div style={{ display: "none" }}>
                          <div id="printJS-div">
                            <table
                              className="table font-12 table-bordered table-sm w-100"
                              style={{
                                border: "1px solid rgb(0,0,0)",
                                color: "rgb(33,37,41)",
                                fontSize: "12px",
                                width: "100%",
                              }}
                            >
                              <thead>
                                <tr></tr>
                              </thead>
                              <tbody></tbody>
                            </table>
                          </div>
                        </div>
                        <button
                          className="btn btn-sm d-inline csv-icon p-0 pl-1 "
                          title="Export Data"
                        >
                          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNTL48hdFgcI1b0Di92lvwE5dULJ5Y7z4-Nc3pGaZd3_66TTlHsFC011gtEA&s" />
                        </button>
                        <button
                          className="btn btn-sm d-inline csv-icon p-0"
                          title="Export Data"
                        >
                          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNTL48hdFgcI1b0Di92lvwE5dULJ5Y7z4-Nc3pGaZd3_66TTlHsFC011gtEA&s" />
                        </button>
                        <button
                          className="btn btn-sm d-inline csv-icon p-0 pl-1"
                          title="Export Data"
                        >
                          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNTL48hdFgcI1b0Di92lvwE5dULJ5Y7z4-Nc3pGaZd3_66TTlHsFC011gtEA&s" />
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <Link to={'/client-form'}>
                      <button className="btn btn-blu float-right">
                        Add Client Account
                      </button>
                      </Link>
                    </div>
                  </div>
                </h5>
              </div>
              <div className="row mt-1">
                <div className="col-12 col-sm-12 col-lg-12 col-md-12 table-responsive">
                  <table id="example"
                    className="table table-striped table-bordered table-sm addpadding nowrap"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>
                          <span>User Name</span>
                          <span
                            className="d-contant"
                            style={{ float: "right" }}
                          >
                            <HiArrowsUpDown />
                          </span>
                        </th>
                        <th>
                          <span>Credit Reference</span>
                          <span
                            className="d-contant"
                            style={{ float: "right" }}
                          >
                            <HiArrowsUpDown />
                          </span>
                        </th>
                        <th>
                          <span>Balance</span>
                          <span
                            className="d-contant"
                            style={{ float: "right" }}
                          >
                            <HiArrowsUpDown />
                          </span>
                        </th>
                        <th>
                          <span>Pending Bal</span>
                          <span
                            className="d-contant"
                            style={{ float: "right" }}
                          >
                            <HiArrowsUpDown />
                          </span>
                        </th>
                        <th>
                          <span>Available Bal</span>
                          <span
                            className="d-contant"
                            style={{ float: "right" }}
                          >
                            <HiArrowsUpDown />
                          </span>
                        </th>
                        <th>
                          <span>Current P&L</span>
                          <span
                            className="d-contant"
                            style={{ float: "right" }}
                          >
                            <HiArrowsUpDown />
                          </span>
                        </th>
                        <th>
                          <span>Exposure</span>
                          <span
                            className="d-contant"
                            style={{ float: "right" }}
                          >
                            <HiArrowsUpDown />
                          </span>
                        </th>
                        <th>U Lock</th>
                        <th>B Lock</th>
                        <th>
                          <span>My %</span>
                          <span
                            className="d-contant"
                            style={{ float: "right" }}
                          >
                            <HiArrowsUpDown />
                          </span>
                        </th>
                        <th>
                          <span>Type</span>
                          <span
                            className="d-contant"
                            style={{ float: "right" }}
                          >
                            <HiArrowsUpDown />
                          </span>
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td><b className="float-right">11300.00</b></td>
                            <td><b className="float-right">14999.00</b></td>
                            <td><b className="float-right">-3690.00</b></td>
                            <td><b className="float-right">10214.38</b></td>
                            <td><b className="float-right">-4775.69</b></td>
                            <td><b className="float-right">00.00</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
