import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { sectionDataAction } from "../../network/store/action/SectionResponseAction";
import { Table } from "react-bootstrap";
import GcSection from "../../components/gc_section";
import GcOption from "../../components/gc_option";
import GcUserLock from "../../components/gc_user_locks";

function Gc() {
  const dispatch = useDispatch();

  const [resultList, setResultList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    await dispatch(sectionDataAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
      }
    });
  };

  return (
    <>
      <main className="container-fluid dashboard game-container">
        <div className="row m-3">
          <h5 className="dashboard-title">Gc</h5>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1 h-scroll">
            {resultList && resultList.length > 0 ? (
              resultList.map((item, index) => {
                return (
                  <div key={index}>
                    {/* <GcSection item ={item}/> */}
                    <GcUserLock
                      item={item}
                      onUpdate={() => {
                        getList();
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <div></div>
            )}

            {/* {
                resultList.map((item,index)=>{

                  return  <div>
                    <label>{item.name}</label>

                    
                  </div>
                })
            } */}

            {/* <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
    
                </tr>
              </thead>
              {resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((el, i) => {
                    return (
                    

                      <tr key={i}>
                      
                        <td className="align-middle">{el.id}</td>
                        <td className="align-middle">{el.name}</td>
                     
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table> */}
          </div>
        </div>
      </main>
    </>
  );
}
export default Gc;
