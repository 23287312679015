import React from 'react'
import { Accordion } from 'react-bootstrap'

function Cricket() {
  return (
    <div>
        <div className='cricket-live' style={{width:'501px',height:'50px',backgroundColor:'blue',marginTop:'30px'}}>
            <h6 style={{color:'white'}}>Ind vs Aus</h6>

        </div>
        <div>
    <Accordion defaultActiveKey="0"  style={{width:'500px'}}>
      <Accordion.Item eventKey="0">
        <Accordion.Header style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>Bookmaker 0 Commission <button>book</button><button>Bl</button><button>BetPlace</button><button>0</button>
        <span/>
        <p>Min:1.00</p><span>|</span><p>Max:1.00</p>
        </Accordion.Header>
            <Accordion.Body>
          
            </Accordion.Body>
        </Accordion.Item>
      
       </Accordion>
        </div>
    </div>
  )
}

export default Cricket