import React, { useState } from "react";
import "./AgentMaster.css";

function AgentMaster() {
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    referenceNumber: "",
    remark: "",
  });

  const [agentData, setAgentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (
      formData.name === "" ||
      formData.mobileNumber === "" ||
      formData.referenceNumber === "" ||
      formData.remark === ""
    ) {
      alert("Please fill in all the required fields.");
    } else {
      setAgentData([...agentData, formData]);
      setFormData({
        name: "",
        mobileNumber: "",
        referenceNumber: "",
        remark: "",
      });
    }
  };

  const handleReset = () => {
    setFormData({
      name: "",
      mobileNumber: "",
      referenceNumber: "",
      remark: "",
    });
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = agentData.slice(indexOfFirstRow, indexOfLastRow);

  const renderRows = () => {
    if (currentRows.length === 0) {
      return (
        <tr>
          <td colSpan={5}>No Data Available...</td>
        </tr>
      );
    }

    return currentRows.map((agent, index) => (
      <tr key={index}>
        <td>{agent.name}</td>
        <td>{agent.mobileNumber}</td>
        <td>{agent.referenceNumber}</td>
        <td>{agent.remark}</td>
        <td>{/* Add logic for Is Active */}</td>
      </tr>
    ));
  };

  const totalPages = Math.ceil(agentData.length / rowsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <div className="agent-container">
        <h5 className="agent-title"> Add Agent Details</h5>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="agent-search-input"
          placeholder="Add Name"
        />
        <input
          type="number"
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleInputChange}
          className="agent-search-input"
          placeholder="Add Mobile Number"
        />
        <input
          type="text"
          name="referenceNumber"
          value={formData.referenceNumber}
          onChange={handleInputChange}
          className="agent-search-input"
          placeholder="Add Reference Number"
        />
        <input
          type="text"
          name="remark"
          value={formData.remark}
          onChange={handleInputChange}
          className="agent-search-input"
          placeholder="Remark"
        />
        <div>
          <button className="go-button" onClick={handleSubmit}>
            Submit
          </button>
          <button className="reset-button" onClick={handleReset}>
            Reset
          </button>
        </div>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
            <div className="table-responsive">
              <table
                className="table font-12 table-bordered border-sm addpadding table-table-striped mt-2"
                id="example"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile Number</th>
                    <th>Reference Number</th>
                    <th>Remarks</th>
                    <th>Is Active</th>
                  </tr>
                </thead>
                <tbody>{renderRows()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="pagination">
          <button
            className="go-button"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <span>
            {" "}
            Page {currentPage} of {totalPages}{" "}
          </span>
          <button
            className="reset-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default AgentMaster;
