import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Protected from "./pages/protected-route";
import Dashboard from "./pages/Home/Dashboard";
import Login from "./auth/login";
import AdminUsers from "./pages/users/Admin";
import Agent from "./pages/users/Agent";
import SuperMaster from "./pages/users/SuperMaster";
import SubSuperMaster from "./pages/users/SubSuperMaster";
import User from "./pages/users/User";
import Employee from "./pages/users/Employee";
import Master from "./pages/users/Master";
import UserDetail from "./pages/analytics/UserDetail";
import AccountStatement from "./pages/analytics/AccountStatement";
import TransReport from "./pages/analytics/TransReport";
import SetbalReport from "./pages/analytics/SetbalReport";
import CurrentBets from "./pages/analytics/CurrentBets";
import PIanalytics from "./pages/analytics/PIReports";
import EventReport from "./pages/analytics/EventReport";
import BetHistory from "./pages/analytics/BetHistory";
import LiveBets from "./pages/analytics/LiveBets";
import SportsRevenue from "./pages/analytics/SportsRevenue";
import AgentMaster from "./pages/Cuttings/AgentMaster";
import CuttingHistory from "./pages/Cuttings/CuttingHistory";
import UserSettle from "./pages/Settlements/UserSettle";
import MasterSettle from "./pages/Settlements/MasterSettle";
import Gc from "./pages/controls/Gc";
import Options from "./pages/controls/Options";
import Sections from "./pages/controls/Sections";
import TransactionList from "./pages/Wallet/TransactionList";
import UserGc from "./pages/controls/UserGc";
import Cricket from "./pages/Games/Cricket";
import Clients from "./pages/users/Clients";
import ClientForm from "./pages/users/ClientForm";
// import Clients from "./pages/Clients/Clients";
export default function Routers() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/login" />} />

      <Route
        path="/dashboard"
        element={<Protected>{<Dashboard />}</Protected>}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/admin" element={<AdminUsers />} />
      <Route path="/agent" element={<Agent />} />
      <Route path="/smaster" element={<SuperMaster />} />
      <Route path="/ssmaster" element={<SubSuperMaster />} />
      <Route path="/master" element={<Master />} />
      <Route path="/user" element={<User />} />
      <Route path="/employee" element={<Employee />} />
      <Route path="/userdetail" element={<UserDetail />} />
      <Route path="/accountstatement" element={<AccountStatement />} />
      <Route path="/transaction" element={<TransReport />} />
      <Route path="/balancereport" element={<SetbalReport />} />
      <Route path="/currentbets" element={<CurrentBets />} />
      <Route path="/profitloss" element={<PIanalytics />} />
      <Route path="/eventPnLReport" element={<EventReport />} />
      <Route path="/bethistory" element={<BetHistory />} />
      <Route path="/livebets" element={<LiveBets />} />
      <Route path="/sportsrevenue" element={<SportsRevenue />} />
      <Route path="/agentmaster" element={<AgentMaster />} />
      <Route path="/cuttinghistory" element={<CuttingHistory />} />
      <Route path="/usersettlement" element={<UserSettle />} />
      <Route path="/mastersettlement" element={<MasterSettle />} />
      <Route path="/gamecontrol" element={<UserGc />} />
      <Route path="/options" element={<Options />} />
      <Route path="/sections" element={<Sections />} />
      <Route path="/wallet" element={<TransactionList />} />
      <Route path="/cricket" element={<Cricket />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/client-form" element={<ClientForm/>}/>
    </Routes>
  );
}
