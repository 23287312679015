import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "./EventReport.css";

const dateFormat = "DD/MM/YYYY";

function EventReport() {
  const [startDate, setStartDate] = useState(dayjs("01/01/1800", dateFormat));
  const [endDate, setEndDate] = useState(dayjs("01/01/1800", dateFormat));
  const [searchText, setSearchText] = useState("");

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    // Add your search functionality here using the 'searchText', 'startDate', and 'endDate' values
    // You can use these values to filter and display transaction data
  };

  const handleReset = () => {
    setStartDate(dayjs("01/01/1800", dateFormat));
    setEndDate(dayjs("01/01/1800", dateFormat));
    setSearchText("");
  };

  return (
    <div className="event-container">
      <h5 className="event-title">Event Profit & Loss Report</h5>
      <input
        type="text"
        className="event-search-input"
        placeholder="Search by Client"
        value={searchText}
        onChange={handleSearchChange}
      />
      <DatePicker
        value={startDate}
        format={dateFormat}
        onChange={handleStartDateChange}
      />
      <DatePicker
        value={endDate}
        format={dateFormat}
        onChange={handleEndDateChange}
      />
      <div>
        <button className="go-button" onClick={handleSearch}>
          Go
        </button>
        <button className="reset-button" onClick={handleReset}>
          Reset
        </button>
      </div>
    </div>
  );
}

export default EventReport;
