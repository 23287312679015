import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../PageFormStyles.css";
import { updateAction } from "../../network/store/action/CreditResponseAction";

const CreditUpdateForm = (props) => {
  // TO get Callbacks
  const dispatch = useDispatch();
  const [itemInfo, setItemInfo] = useState(props.itemInfo);

  // Page Form
  const [dataForm, setDataForm] = useState({
    availableBalance: "",
    depositAmount: "",
    remarks: "",
    password: "",
  });
  useEffect(() => {
    if (itemInfo) {
      let updateValues = {
        availableBalance:itemInfo.chipPoints,
        remarks:itemInfo.remarks
      };
      setDataForm(updateValues);
    }
  }, []);

  //Loading
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isStoring, setIsStoring] = useState(false);

  const handleInputChanges = (e) => {
    if (e.target.name === "mobilenumber") {
      const numericValue = e.target.value.replace(/\D/g, "");
      const truncatedValue = numericValue.slice(0, 10);
  
      setDataForm({
        ...dataForm,
        [e.target.name]: truncatedValue,
      });
    } else if (e.target.name === "depositAmount") {
      const numericValue = e.target.value.replace(/\D/g, "");
      const truncatedValue = numericValue.slice(0, 7); 
      const depositAmount = parseFloat(truncatedValue) || 0;
  
      if (depositAmount > 1000000) {
        showAlert("Deposit amount cannot exceed 10 lakh rupees.");
      } else {
        setDataForm({
          ...dataForm,
          [e.target.name]: truncatedValue,
        });
      }
    } else {
      setDataForm({
        ...dataForm,
        [e.target.name]: e.target.value,
      });
    }
  };
  const onSubmtClick = async () => {
    let requestBody = {
      amount: dataForm.depositAmount,
      userId: itemInfo.id,
      superUserId: localStorage.getItem("userId"),
      remarks: dataForm.remarks,

    };
   
    await dispatch(updateAction(requestBody)).then((response) => {
      setIsStoring(false);
      console.log(response.status);
      console.log(response.message);
      if (response.status === true) {
        // showAlert(response.message);
        if (props.onClose) props.onClose();
      } else {
        showAlert(response.result);
      }
    });
   
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      <div>
        <div className="container">
          <div className="row mt-5" style={{ padding: "20px" }}>
            <div className="col-md-5 card-1 p-5 m-0">
              <h5 className="text-center dashboard-title">Deposit Amount</h5>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="label-style">Available Balance</label>
                  <input
                    type="number"
                    name="availableBalance"
                    className="form-control"
                    value={dataForm.availableBalance}
                    // onChange={(e) => handleInputChanges(e)}
                    readOnly
                  />
                  <label className="label-style">Depostit Amount</label>
                  <input
                    type="number"
                    name="depositAmount"
                    className="form-control"
                    value={dataForm.depositAmount}
                    onChange={(e) => handleInputChanges(e)}
                  />  

                  <label className="label-style">Remarks</label>
                  <input
                    type="text"
                    name="remarks"
                    className="form-control"
                    value={dataForm.remarks}
                    onChange={(e) => handleInputChanges(e)}
                  />
                  <label className="label-style">Master Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    value={dataForm.password}
                    onChange={(e) => handleInputChanges(e)}
                  />

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{ display: "flex", justifyContent: "center" }}
                      type="button"
                      className="s-button"
                      onClick={() => onSubmtClick()}
                    >
                      Submit
                    </button>
                    <button
                      className="c-button"
                      onClick={() => {
                        if (props.onClose) props.onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditUpdateForm;