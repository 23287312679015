import React, { useState } from "react";
import "./LiveBets.css";
function LiveBets() {
  const options = ["Cricket", "Soccer", "Tennis"];
  const option1 = ["Select Event"];
  const option2 = [""];
  const option3 = [""];
  const [option, setOption] = useState("");
  const [opt, setOpt] = useState("");
  const [opt2, setOpt2] = useState("");
  const [opt3, setOpt3] = useState("");
  const handleOptionChange1 = (options) => {
    setOption(options);
  };
  const handleOptionChange2 = (option1) => {
    setOpt(option1);
  };
  const handleOptionChange3 = (option2) => {
    setOpt2(option2);
  };
  const handleOptionChange4 = (option3) => {
    setOpt3(option3);
  };
  const handleStart = () => {};
  const handleStop = () => {};
  const handleReset = () => {};
  return (
    <div className="livebets-container">
      <h5 className="livebets-title">Live Bets</h5>

      <select
        title="Sport"
        className="livebets-option-select"
        value={option}
        onChange={(e) => handleOptionChange1(e.target.value)}
      >
        <option>Select Sport</option>
        {options.map((options, index) => (
          <option key={index} value={options}>
            {options}
          </option>
        ))}
      </select>
      <select
        title="Event"
        className="livebets-option-select"
        value={opt}
        onChange={(e) => handleOptionChange2(e.target.value)}
      >
        <option>Select Event</option>
        {option1.map((option1, index) => (
          <option key={index} value={option1}>
            {option1}
          </option>
        ))}
      </select>
      <select
        title="Market"
        className="livebets-option-select"
        value={opt2}
        onChange={(e) => handleOptionChange3(e.target.value)}
      >
        <option value="" disabled>
          Select Market Type
        </option>
        {option2.map((option2, index) => (
          <option key={index} value={option2}>
            {option2}
          </option>
        ))}
      </select>

      <select
        title="Market Types"
        className="livebets-option-select"
        value={opt3}
        onChange={(e) => handleOptionChange4(e.target.value)}
      >
        <option value="" disabled>
          {" "}
          Market Type
        </option>
        {option3.map((option3, index) => (
          <option key={index} value={option3}>
            {option3}
          </option>
        ))}
      </select>
      <input
        type="number"
        className="live-search-input"
        placeholder="Amount From"
      />
      <input
        type="number"
        className="live-search-input"
        placeholder="Amount To"
      />
      <input
        type="text"
        className="live-search-input"
        placeholder="Search by Client"
      />
      <div>
        <button className="start-button" onClick={handleStart}>
          Start
        </button>
        <button className="stop-button" onClick={handleStop}>
          Stop
        </button>
        <button className="reset-button" onClick={handleReset}>
          Reset
        </button>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
          <div className="table-responsive">
            <table
              className="table font-12 table-bordered border-sm addpadding mt-2 whitespace"
              id="example"
            >
              <thead>
                <tr>
                  <th>Time</th>
                  <th>User</th>
                  <th>Competition</th>
                  <th>Event</th>
                  <th>Market</th>
                  <th>Runner</th>
                  <th>Side</th>
                  <th>Line</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={10}>No Data Found...</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveBets;
