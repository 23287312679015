import React, { useState } from "react";
import "./MasterSettle.css";

function MasterSettle() {
  const [remarks, setRemarks] = useState("");
  const [password, setPassword] = useState("");
  const handleFillAllClick = () => {
    //to fill all fields as needed
    setRemarks("All fields filled.");
  };

  const handleSubmitClick = () => {
    //  to handle form submission with password validation
    if (password === "yourPassword") {
      alert("Form submitted successfully!");
    } else {
      alert("Invalid password. Submission failed.");
    }
  };

  const handleClearAllClick = () => {
    setRemarks("");
    setPassword("");
  };

  return (
    <>
      <div className="master-container">
        <h5 className="user-title">Master Settlement</h5>

        {/* <table className="credit-table">
        <thead>
          <tr>
            <th colSpan="4">Creditors Account (Dena Hai)</th>
          </tr>
          <tr>
            <th>Account</th>
            <th>Client(P/L)</th>
            <th>Settle Amount</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {creditors.map((creditor) => (
            <tr key={creditor.id}>
              <td>{creditor.account}</td>
              <td>{creditor.client}</td>
              <td>{creditor.settleamount}</td>
              <td>{creditor.remarks}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="debit-table">
        <thead>
          <tr>
            <th colSpan="4">Debitors Account (Lena Hai)</th>
          </tr>
          <tr>
            <th>Account</th>
            <th>Client(P/L)</th>
            <th>Settle Amount</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {debtors.map((debtor) => (
            <tr key={debtor.id}>
              <td>{debtor.account}</td>
              <td>{debtor.client}</td>
              <td>{debtor.settleamount}</td>
              <td>{debtor.remarks}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
            <div className="table-responsive">
              <table className="table font-12 table-bordered border-sm addpadding mt-2"id="example">
                <thead>
                  <tr>
                    <th colSpan={5}>
                      Creditors Account(Dena Hai)
                    </th>
                  </tr>
                  <tr>
                    <th>Account</th>
                    <th>Client(P/L)</th>
                    <th>Settle Amount</th>
                    <th>Short Bal</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="d-flex">
                        <input style={{width:'100px'}}
                          type="number"
                          placeholder
                        />
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <input style={{width:'100px'}}
                        type="text"
                        name="description"
                        placeholder="Remarks"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
            <div className="table-responsive">
              <table className="table font-12 table-bordered border-sm addpadding mt-2"id="example">
                <thead>
                  <tr>
                    <th colSpan={5}>
                      Debitors Account(Lena Hai)
                    </th>
                  </tr>
                  <tr>
                    <th>Account</th>
                    <th>Client(P/L)</th>
                    <th>Settle Amount</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="d-flex">
                        <input
                          type="number"
                          placeholder=""
                          style={{width:'100px'}}
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="description"
                        placeholder="Remarks"
                        style={{width:'100px'}}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div>
        <input
          type="text"
          className="client-search-input"
          placeholder="Remarks"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />
        <button className="stop-button" onClick={handleFillAllClick}>
          Fill All
        </button>
        <input
          type="password"
          className="client-search-input"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="go-button" onClick={handleSubmitClick}>
          Submit
        </button>
        <button className="reset-button" onClick={handleClearAllClick}>
          Clear All
        </button>
      </div>
    </>
  );
}

export default MasterSettle;
