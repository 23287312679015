import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import './SetbalReport.css';
const dateFormat = "DD/MM/YYYY";

function SetbalReport() {
  const setOptions = ["Balance Transactions", "Settlement Transactions"];
  const setOptions1 = ["ALL", "Debit", "Credit"];

  const [selectedOption, setSelectedOption] = useState();
  const [selectedOption1, setSelectedOption1] = useState("");
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(dayjs("01/09/2023", dateFormat));
  const [endDate, setEndDate] = useState(dayjs("01/09/2023", dateFormat));

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleOptionChange1 = (value) => {
    setSelectedOption1(value);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearch = () => {
    // search functionality here using 'selectedOption', 'selectedOption1', 'searchText', 'startDate', and 'endDate'
  };

  const handleReset = () => {
    setSelectedOption("");
    setSelectedOption1("");
    setSearchText("");
    setStartDate(dayjs("01/09/2023", dateFormat));
    setEndDate(dayjs("01/09/2023", dateFormat));
  };

  return (
    <div className="setbal-report-container">
      <h5 className="setbal-report-title">Settlement/Balance Transaction Report</h5>
      <select
        title="Balance"
        className="setbal-option-select"
        value={selectedOption}
        onChange={(e) => handleOptionChange(e.target.value)}
      >
        {setOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <select
        title="types"
        className="setbal-option-select"
        value={selectedOption1}
        onChange={(e) => handleOptionChange1(e.target.value)}
      >
        {setOptions1.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        type="text"
        className="setbal-search-input"
        placeholder="Search by Client"
        value={searchText}
        onChange={handleSearchChange}
      />
      <DatePicker
        value={startDate}
        format={dateFormat}
        onChange={handleStartDateChange}
      />
      <DatePicker
        value={endDate}
        format={dateFormat}
        onChange={handleEndDateChange}
      />
      <div>
        <button className="go-button" onClick={handleSearch}>
          Go
        </button>
        <button className="reset-button" onClick={handleReset}>
          Reset
        </button>
      </div>
    </div>
  );
}

export default SetbalReport;
