import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  listAction,
  createAction,
} from "../../network/store/action/UserResponseAction";
import noImage from "../../assets/no_image.jpg";
import GMCTPagination from "../../components/pagination";
// import { Table } from "react-bootstrap";
import {
  FaUserLock,
  FaTrash,
  FaEdit,
  FaPlus,
  FaAngleDoubleDown,
} from "react-icons/fa";
import AddUpdateForm from "./add-update-form";
import { MdOutlinePassword } from "react-icons/md";
import { CiCreditCard1 } from "react-icons/ci";

function User() {
  // to get api data

  const dispatch = useDispatch();

  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  // list of items
  const [resultList, setResultList] = useState([]);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [selectedForEdit, setSelectedForEdit] = useState({});
  const [showRequestForm, setShowRequestForm] = useState(false);

  useEffect(() => {
    geList(pageLimit, pageOffset);
  }, []);

  useEffect(() => {
    geList(pageLimit, pageOffset);
  }, [pageLimit, pageOffset]);

  const geList = async (limit, offset) => {
    await dispatch(
      listAction(
        {
          limit: limit,
          offset: offset,
          roleId: "7",
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
        setTotalCount(Number(reponse.itemCount.totalFilterCount));
        setPageCount(reponse.itemCount.pageCount);
      } else {
        resetFilter();
      }
    });
  };

  const editItem = (item) => {
    setSelectedForEdit(item);
    setShowRequestForm(true);
  };

  const onCloseEvent = () => {
    setShowRequestForm(false);
    setResultList([]);
    setSelectedForEdit({});

    geList(pageLimit, pageOffset);
    resetFilter();
  };

  const resetFilter = () => {
    setPageOffset(0);
    setTotalCount(0);
    setResultList([]);
    setPageCount(0);
    setActivePage(1);
  };

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  };
  return (
    <>
      {showRequestForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "#FFFFFF",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
            marginTop: "10px",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-8">
              <AddUpdateForm
                onClose={() => {
                  console.log("AddCategory : closed.");
                  onCloseEvent();
                }}
                itemInfo={selectedForEdit}
                pageName="User"
                roleId="7"
              />
            </div>
          </div>
        </div>
      )}
      <div className="account-statement-container">
        <h4 className="account-statement-title">User</h4>
        <div className="row justify-content-around tble">
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn-primary"
              onClick={() => setShowRequestForm(true)}
            >
              Add User
            </button>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
            <div className="table-responsive">
              <table className="table font-12 table-bordered border-sm addpadding mt-2"id="example">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Phone No</th>
                    <th>Sharing </th>
                    <th>Chip Points</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {resultList && resultList.length > 0 ? (
                  <tbody>
                    {resultList.map((el, i) => {
                      return (
                        <tr key={i}>
                          {/* <th scope="row">{od.id}</th> */}
                          <td className="align-middle">{el.id}</td>

                          <td className="align-middle">{el.name}</td>
                          <td className="align-middle">{el.phone}</td>
                          <td className="align-middle">{el.sharing}</td>
                          <td className="align-middle">{el.chipPoints}</td>
                          {/* <td className="align-middle">
                          <p>{props.el.prices.name}</p>
                        </td> */}
                          <td className="align-middle">
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={el.status === "YES" ? true : false}
                              />
                            </div>
                            {/* <p><input class="form-check-input" type="checkbox" role="switch"  checked={props.el.status === "YES" ? true : false}/></p> */}
                          </td>
                          <td className="align-middle">
                            <div style={{display:'flex'}}>
                              <span className="edit-icon-btn"
                                onClick={() => {
                                  // creditItem(el);
                                }}
                              >
                                <CiCreditCard1 />
                              </span>
                              <span className="edit-icon-btn"
                                onClick={() => {
                                  // debitItem(el);
                                }}
                              >
                                <FaAngleDoubleDown />
                              </span>
                              <span className="edit-icon-btn"
                                onClick={() => {
                                  // setShowDebitForm(true);
                                }}
                              >
                                <MdOutlinePassword />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <p>No Data found</p>
                )}
              </table>
            </div>

            {/* <GMCTPagination
              pageCount={pageCount}
              activePage={activePage}
              totalCount={totalCount}
              onPageChange={(page) => handlePageChange(page)}
              onLimitChange={(e) => handleLimitChange(e)}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default User;
