import { DEBIT_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";



export const updateRequest = (data) => async () => {
  try {
    let result = await postRequest(DEBIT_API_PATH.UPDATE_DEBIT, data, false);
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};