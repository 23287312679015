import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createAction } from "../../network/store/action/UserResponseAction";
import "../../PageFormStyles.css";
import { useEffect } from "react";
import { Dropdown, Toast } from "react-bootstrap";

const AddUpdateForm = (props) => {
  // const accoptions = ['ALL', 'BALANCE REPORT', 'GAME REPORT', 'SETTLEMENT REPORT', 'BONUS REPORT'];
  const [clientName, setClientName] = useState("");
  const [accountStatements, setAccountStatements] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (accoptions) => {
    setSelectedOption(accoptions);
  };

  const handleClientNameChange = (e) => {
    setClientName(e.target.value);
  };
  // TO get Callbacks
  const dispatch = useDispatch();
  const [itemInfo, setItemInfo] = useState(props.itemInfo);

  // to dsplay error/ promt message
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState("");

  // Page Form
  const [dataForm, setDataForm] = useState({
    displayname: "",
    mobilenumber: "",
    sharing: "",
  });
  useEffect(() => {
    if (itemInfo) {
      let updateValues = {
        displayname: itemInfo.displayname,
        mobilenumber: itemInfo.mobilenumber,
        sharing: itemInfo.sharing,
      };
      setDataForm(updateValues);
    }
  }, []);

  //Loading
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isStoring, setIsStoring] = useState(false);

  const handleInputChanges = (e) => {
    if (e.target.name === "mobilenumber") {
      const numericValue = e.target.value.replace(/\D/g, "");
      const truncatedValue = numericValue.slice(0, 10);

      setDataForm({
        ...dataForm,
        [e.target.name]: truncatedValue,
      });
    } else {
      setDataForm({
        ...dataForm,
        [e.target.name]: e.target.value,
      });
    }
  };
  const calculateRemainingSharing = (sharing) => {
    // Assuming the total percentage is 100
    const totalPercentage = localStorage.getItem("sharing");
    const enteredPercentage = parseFloat(sharing) || 0;
    const remainingPercentage = totalPercentage - enteredPercentage;

    return remainingPercentage.toFixed(2);
  };

  const handleInputChanges1 = (e) => {
    if (e.target.name === "mobilenumber") {
      const numericValue = e.target.value.replace(/\D/g, "");
      const truncatedValue = numericValue.slice(0, 10);
      setDataForm({
        ...dataForm,
        [e.target.name]: truncatedValue,
      });
    } else if (e.target.name === "sharing") {
      // /[^\d.]/g, "" for decimals
      const numericValue = e.target.value.replace(/[^\d.]/g, "");
      const truncatedValue = numericValue.slice(0, 5);
      const sharingValue = Math.min(
        Math.max(parseFloat(truncatedValue) || 0, 0),
        100
      );
      const remainingSharing = calculateRemainingSharing(
        sharingValue.toFixed(2)
      );

      if (remainingSharing <= parseFloat(localStorage.getItem("sharing"))) {
        setDataForm({
          ...dataForm,
          [e.target.name]: truncatedValue,
          remainingSharing: remainingSharing,
        });
      } else {
        setShow(true);
        setShowMessage("% Share exceeded");
      }
    } else {
      setDataForm({
        ...dataForm,
        [e.target.name]: e.target.value,
      });
    }
  };
  const onSubmtClick = async () => {
    let requestBody = {
      name: dataForm.displayname,
      phone: dataForm.mobilenumber,
      password: dataForm.mobilenumber,
      email: dataForm.mobilenumber + "@mail.com",
      sharing: dataForm.sharing,
      status: "ACTIVE",
      role_id: props.roleId,
    };

    await dispatch(createAction(requestBody)).then((response) => {
      setIsStoring(false);
      console.log(response.status);
      console.log(response.message);
      if (response.status === true) {
        // showAlert(response.message);
        if (props.onClose) props.onClose();
      } else {
        showAlert(response.result);
      }
    });
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  // const handleGo = () => {
  //   // Make an API request to fetch account statements based on selected options, client name, and date
  //   // Update 'accountStatements' state with the fetched data

  // };

  const handleReset = () => {
    setClientName("");
    setSelectedOption("");
    setAccountStatements([]);
  };

  return (
    <>
      <div>
        {/* <div className="account-statement-container">
      <h5 className="account-statement-title">Account Statement Report</h5>
      <select
        title='types'
        className="account-option-select"
        value={selectedOption}
        onChange={(e) => handleOptionChange(e.target.value)}
      >
        {accoptions.map((accoption, index) => (
          <option key={index} value={accoption}>
            {accoption}
          </option>
        ))}
      </select> */}
      </div>
      <div className="container">
        <div className="row mt-5" style={{ padding: "20px" }}>
          <div className="col-md-5 card-1 p-5 m-0">
            <h5 className="text-center dashboard-title">
              Add {props.pageName}
            </h5>

            <div className="col-md-12">
              <div className="form-group">
                <label className="label-style"> Name</label>
                <input
                  type="text"
                  name="displayname"
                  className="form-control"
                  value={dataForm.displayname}
                  onChange={(e) => handleInputChanges(e)}
                />
                <label className="label-style">Mobile Number</label>
                <input
                  type="text"
                  name="mobilenumber"
                  className="form-control"
                  value={dataForm.mobilenumber}
                  onChange={(e) => handleInputChanges(e)}
                />
                <label className="label-style">Sharing(%)</label>
                <input
                  type="text"
                  name="sharing"
                  className="form-control"
                  value={dataForm.sharing}
                  onChange={(e) => handleInputChanges1(e)}
                />
                <label className="label-style">Remaining Sharing(%)</label>
                <input
                  type="text"
                  name="remainingSharing"
                  className="form-control"
                  value={dataForm.remainingSharing}
                  readOnly
                />
                
                

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    style={{ display: "flex", justifyContent: "center" }}
                    type="button"
                    className="s-button"
                    onClick={() => onSubmtClick()}
                  >
                    Submit
                  </button>
                  <button
                    className="c-button"
                    onClick={() => {
                      if (props.onClose) props.onClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 10,
        }}
      >
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header className="table" closeButton={false}>
            <strong className="mr-auto">Alert !!</strong>
          </Toast.Header>
          <Toast.Body className="table">{showMessage}</Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default AddUpdateForm;
