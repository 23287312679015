export const USER_API_PATH = {
  LOGIN_PATH: "Auth/login",
  REGISTRATION_PATH: "Auth/addUser",
  UPDATE_USER: "User/update",
  UPDATE_USER_PWD: "User/updatePassword",
  USER_INFO: "User/info?id=",
  LIST_PATH: "User/listUsers",
};
export const SECTIONS_API_PATH = {
  LIST_PATH: "Section/list",
  CREATE_UPDATE: "Section/update",
  SECTION_DATA: "Section/sectionData",
};

export const WALLET_API_PATH = {
  WALLET_INFO: "UserWallet/walletBalence",
  WALLET_TRANSACTION_LOG: "UserWallet/transactionLog",
};

export const CREDIT_API_PATH = {
  UPDATE_CREDIT: "UserWallet/update",
};
export const DEBIT_API_PATH = {
  UPDATE_DEBIT: "UserWallet/debit",
};

export const OPTIONS_API_PATH = {
  LIST_PATH: "Category/list",
  CREATE_UPDATE: "Category/update",
};

export const GC_API_PATH = {
  SECTION_UPDATE: "Section/updateSection",
  OPTIONS_UPDATE: "Category/updateCategory",
};
